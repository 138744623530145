
















import {
  Component, Emit, Prop, Vue,
} from 'vue-property-decorator';
import BarChart from '@/components/charts/BarChart.vue';
import DonutChart from '@/components/charts/DonutChart.vue';

export interface ChartChangerState {
  showBarChart: boolean;
  showBarChartHorizontal: boolean;
  showDonutChart: boolean;
}

@Component({
  components: {
    BarChart,
    DonutChart,
  },
})

export default class ChartChanger extends Vue {

  @Prop({ required: true })
  private seriesData!: number[];

  @Prop({ default: () => ([]) })
  private labels!: string[];

  @Prop({ default: false })
  private showSmileys!: boolean;

  @Prop({ default: true })
  private showChartSelector!: boolean;

  @Prop({
    default: () => ({
      showBarChart: true,
      showBarChartHorizontal: false,
      showDonutChart: false,
    }),
  })
  private chartDisplayStates!: ChartChangerState;

  private currentDisplayState: ChartChangerState = this.chartDisplayStates;

  @Emit()
  private chartChanged() {
    return this.currentDisplayState;
  }

  private showChart(showBarChart: boolean, showBarChartHorizontal: boolean, showDonutChart: boolean) {
    this.currentDisplayState.showBarChart = showBarChart;
    this.currentDisplayState.showBarChartHorizontal = showBarChartHorizontal;
    this.currentDisplayState.showDonutChart = showDonutChart;
    this.chartChanged();
  }
}
