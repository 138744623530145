












import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';

@Component({})
export default class BarChart extends Vue {

  @Prop({ required: true })
  private seriesData!: number[];

  @Prop({ default: () => ([]) })
  private labels!: string[];

  @Prop({ default: false })
  private showSmileys!: boolean;

  @Watch('horizontal')
  private onHoritonzalChanged() {
    const { barChart } = this.$refs;
    if (barChart) {
      (barChart as any).updateOptions({
        plotOptions: {
          bar: {
            horizontal: this.horizontal,
          },
        },
        xaxis: {
          labels: {
            show: !this.showSmileys || this.horizontal,
          },
        },
      });
    }
  }

    @Prop({ default: false })
    private horizontal!: boolean;

    private series = [{}];

    private mounted() {

      const data = [];

      for (let i = 0; i < this.seriesData.length; i += 1) {
        data.push({ x: this.labels[i], y: this.seriesData[i] });
      }

      this.series = [{
        data: data,
      }];
    }

    private chartOptions = {
      chart: {
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false,
        },
        type: 'bar',
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: this.horizontal,
        },
      },
      legend: {
        show: false,
      },
      xaxis: {
        labels: {
          show: !this.showSmileys || this.horizontal,
        },
      },
      labels: this.labels,
      dataLabels: {
        enabled: false,
      },
      grid: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    };

}
