






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class AreaChart extends Vue {

  @Prop({ default: () => ([]) })
  private seriesData!: number[];

  @Prop({ default: () => ([]) })
  private labels!: string[];

  @Prop({ default: '#EB656F' })
  private startColor!: string;

  @Prop({ default: '#95DA74' })
  private endColor!: string;

  private middleColor = '';

  private series = [{}];

  private chartOptions = {
    chart: {
      redrawOnParentResize: true,
      toolbar: {
        show: false,
      },
      type: 'area',
      height: 350,
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: false,
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        type: 'horizontal',
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        colorStops: [
          {
            offset: 0,
            color: this.startColor,
            opacity: 1,
          },
          {
            offset: 100,
            color: this.endColor,
            opacity: 1,
          },
        ],
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val: number) => {
        if (val < 5) {
          return '';
        }
        return `${val}%`;
      },
      background: {
        enabled: false,
      },
      textAnchor: 'middle',
      style: {
        fontSize: '14px',
        colors: ['black'],
      },
      offsetY: -2,
    },
    stroke: {
      curve: 'smooth',
      colors: ['white'],
    },
    labels: this.labels,
    legend: {
      show: false,
    },
    yaxis: {
      min: 0,
      max: 100,
      show: false,
      labels: {
        show: true,
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
    },
    markers: {
      colors: ['red'],
      strokeColors: 'red',
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
  };

  private created() {

    const totalNumberOfAnswers = this.seriesData.reduce((sum, current) => sum + current, 0);
    const percentSeries: number[] = [];

    this.seriesData.forEach((value) => {
      if (value > 0) {
        percentSeries.push(Math.round((value / totalNumberOfAnswers) * 100));
      }
    });

    this.series = [{
      name: '%',
      data: percentSeries,
    }];
  }
}
