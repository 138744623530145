
























































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ProjectOverviewRestDto } from '@/apis/projectapi';
import MessageBox from '@/components/partials/MessageBox.vue';
import MessageBoxType from '@/assets/messageBoxTypes';
import VueHtml2pdf from '@/components/pdf/vue-html2pdf.vue';
import PaginatedFreeTextAnswerList from '@/components/surveys/PaginatedFreeTextAnswerList.vue';
import Services from '@/assets/services/Services';
import {
  AnswerEvaluation,
  QuestionType,
  SurveyEvaluationResult,
  SurveyQuestionAnswerRestDTO,
  SurveyQuestionRestDTO,
  SurveyQuestionRestDTOScaleTypeEnum,
  SurveyRestDTO,
} from '@/apis/surveyapi';
import TextHeader from '@/components/partials/TextHeader.vue';
import TextInput from '@/components/partials/TextInput.vue';
import TextSelect from '@/components/partials/TextSelect.vue';
import TextInputArea from '@/components/partials/TextInputArea.vue';
import RoundedButtonFilled from '@/components/partials/RoundedButtonFilled.vue';
import LoadingAnimation from '@/components/partials/LoadingAnimation.vue';
import Header from '@/assets/headers';
import AreaChart from '@/components/charts/AreaChart.vue';
import BarChart from '@/components/charts/BarChart.vue';
import ChartChanger, { ChartChangerState } from '@/components/charts/ChartChanger.vue';
import BackLinkWithArrow from '@/components/partials/BackLinkWithArrow.vue';
import ButtonType from '@/assets/buttonTypes';
import DateService from '@/assets/services/DateService';
import Page from '@/components/partials/Page.vue';

interface QuestionChartData {
  series: number[];
  labels: string[];
}

@Component({
  components: {
    MessageBox,
    PaginatedFreeTextAnswerList,
    TextHeader,
    TextInput,
    TextSelect,
    TextInputArea,
    RoundedButtonFilled,
    LoadingAnimation,
    AreaChart,
    BarChart,
    ChartChanger,
    BackLinkWithArrow,
    VueHtml2pdf,
    Page,
  },
  metaInfo: {
    title: 'Umfragen │ innovaMo',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: '',
      },
    ],
  },
})
export default class SurveyEvaluation extends Vue {
    private Header = Header;
    private ButtonType = ButtonType;
    private QuestionType = QuestionType;
    private ScaleQuestionType = SurveyQuestionRestDTOScaleTypeEnum;
    private MessageBoxType = MessageBoxType;
    private initialLoading: boolean = true;
    private showUnexpectedErrorMessage: boolean = false;
    private isPdfGenerating = false;
    private downloadPdfDirectlyWithoutPreview = false;

    @Prop({ default: undefined })
    private readonly surveyUUID!: string;

    private survey!: SurveyRestDTO;
    private surveyEvaluation!: SurveyEvaluationResult[];
    private appSpecificSurveyAppName: string = '';

    private questionIdChartDataMap: Map<number, QuestionChartData> = new Map<number, QuestionChartData>();
    private scaleQuestionIdChartDataMap: Map<number, QuestionChartData> = new Map<number, QuestionChartData>();
    private freeTextQuestionIdChartDataMap: Map<number, QuestionChartData> = new Map<number, QuestionChartData>();
    private scaleQuestionIdChartChangerStateMap: Record<number, ChartChangerState> = {};

    private dateTimeFormatter = DateService.dateTimeFormatter;

    private get startTime() {
      if (this.survey && this.survey.time_start) {
        return this.dateTimeFormatter.format(new Date(Date.parse(this.survey.time_start)));
      }

      return '';
    }

    private get endTime() {
      if (this.survey && this.survey.time_end) {
        return this.dateTimeFormatter.format(new Date(Date.parse(this.survey.time_end)));
      }

      return '';
    }

    private async mounted() {
      try {
        const surveyResponse = await Services.surveys.getSurveyById(this.surveyUUID);
        this.survey = surveyResponse.data;

        const evaluationResponse = await Services.surveys.getSurveyEvaluationById(this.surveyUUID);
        this.surveyEvaluation = evaluationResponse.data;

        if (this.survey.isAppSpecific) {
          const developerAppsResponse = await Services.projects.getProjectsByDeveloperUserId(this.survey.developer_id as string);
          this.appSpecificSurveyAppName = developerAppsResponse.data.filter((project: ProjectOverviewRestDto) => project.surveyKey === this.survey.mobileAppKey)[0].name as string;
        }

        this.surveyEvaluation.forEach((e: SurveyEvaluationResult) => {
          if (e && e.answerCounts && e.answerCounts.length > 0) {
            const series: number[] = [];
            const labels: string[] = [];
            let isFreeTextQuestion: boolean = false;

            e.answerCounts.forEach((a: AnswerEvaluation) => {
              series.push(a.count as number);
              labels.push(a.freetextanswer ? a.freetextanswer : `${a.answerId}`);
              isFreeTextQuestion = !!a.freetextanswer;
            });

            if (e.subQuestionId) {
              this.scaleQuestionIdChartDataMap.set(e.subQuestionId as number, { series: series, labels: labels });
              this.scaleQuestionIdChartChangerStateMap[e.subQuestionId] = {
                showBarChart: true,
                showBarChartHorizontal: false,
                showDonutChart: false,
              };
            } else if (isFreeTextQuestion) {
              this.freeTextQuestionIdChartDataMap.set(e.questionId as number, { series: series, labels: labels });
            } else {
              this.questionIdChartDataMap.set(e.questionId as number, { series: series, labels: labels });
            }
          }
        });

      } catch (e) {
        this.showUnexpectedErrorMessage = true;
      } finally {
        this.initialLoading = false;
      }
    }

    private getSubQuestionText(question: SurveyQuestionRestDTO, answerId: number) {
      if (question && question.choice_answers) {
        const answer: SurveyQuestionAnswerRestDTO | undefined = question.choice_answers.find((a) => a.id === answerId);

        if (answer) {
          return answer.answer_text;
        }
      }

      return undefined;
    }

    private chartSelectionChanged(scaleQuestionId: number, newChartState: ChartChangerState) {
      this.scaleQuestionIdChartChangerStateMap[scaleQuestionId].showBarChart = newChartState.showBarChart;
      this.scaleQuestionIdChartChangerStateMap[scaleQuestionId].showBarChartHorizontal = newChartState.showBarChartHorizontal;
      this.scaleQuestionIdChartChangerStateMap[scaleQuestionId].showDonutChart = newChartState.showDonutChart;
    }

    private exportPDF() {
      this.downloadPdfDirectlyWithoutPreview = window.screen.width < 640;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.$refs.html2Pdf.generatePdf();
    }

    private setIsPdfGenerating(isGenerating: boolean) {
      this.isPdfGenerating = isGenerating;
    }

}
