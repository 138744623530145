






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class DonutChart extends Vue {

    @Prop({ required: true })
    private seriesData!: number[];

    @Prop({ default: () => ([]) })
    private labels!: string[];

    private chartOptions = {
      chart: {
        toolbar: {
          show: false,
        },
        animations: {
          enabled: false,
        },
        height: 350,
        type: 'pie',
      },
      labels: this.labels,
      tooltip: {
        enabled: false,
      },
    };

}
