



















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class PaginatedFreeTextAnswerList extends Vue {

  @Prop({ default: 5 })
  private itemsPerPage!: number;

  @Prop({ required: true })
  private freeTextAnswers!: string[];

  private currentPage: number = 0;
  private numberOfPages: number = Math.ceil(this.freeTextAnswers.length / this.itemsPerPage);

  private get itemsToDisplay(): string[] {
    return this.freeTextAnswers.slice(this.currentPage * this.itemsPerPage, (this.currentPage + 1) * this.itemsPerPage);
  }

  private previous() {
    if (this.currentPage > 0) {
      this.currentPage -= 1;
    }
  }

  private next() {
    if (this.currentPage < this.numberOfPages - 1) {
      this.currentPage += 1;
    }
  }
}
